import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {Application} from '../../../../../database-models';

@Injectable()
export class ApplicationService {


  private apiURL = environment.apiURL;

  constructor(private http: HttpClient, private router: Router) {
  }

  public applicate(application: Application) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/application/', application, {headers});
  }

  public approveEmail(body: any) {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/email/check/', body, {headers});
  }
}
